import React, { useContext, useState } from 'react'

const SelectedEntityContext = React.createContext({
  selectedEntity: null,
  setSelectEntity: () => {},
})

export const useSelectedEntityContext = () => {
  return useContext(SelectedEntityContext)
}

export const SelectedEntityContextProvider = ({ children }) => {
  const [selectedEntity, setSelectedEntity] = useState(null)
  const [keptEntities, setKeptEntities] = useState(null)

  return (
    <SelectedEntityContext.Provider
      value={{
        selectedEntity,
        setSelectedEntity,
        keptEntities,
        setKeptEntities,
      }}
    >
      {children}
    </SelectedEntityContext.Provider>
  )
}

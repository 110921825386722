import React, { useContext, useState } from 'react'

const EditorContentContext = React.createContext({
  editorContent: null,
  setEditorContent: () => { },
  scrollToEnd: false,
  setScrollToEnd: () => { },
})

export const useEditorContentContext = () => {
  return useContext(EditorContentContext)
}

export const EditorContentContextProvider = ({ children }) => {
  const [editorContent, setEditorContent] = useState(null)
  const [scrollToEnd, setScrollToEnd] = useState(false)

  return (
    <EditorContentContext.Provider
      value={{
        editorContent,
        setEditorContent,
        scrollToEnd,
        setScrollToEnd,
      }}
    >
      {children}
    </EditorContentContext.Provider>
  )
}

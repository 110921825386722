import React from 'react'
import { render } from 'react-dom'
import { InertiaApp } from '@inertiajs/inertia-react'
import { ToastContainer } from 'react-toastify'
import GoogleAnalytics from '@/Components/GoogleAnalytics'
import { SelectedEntityContextProvider } from '@/Components/EntityContext'
import { EditorContentContextProvider } from '@/Components/EditorContext'

// These files are necessary here for the TreeView component
import 'jstree/dist/jstree.min'
import 'jstree/dist/themes/default/style.css'

const app = document.getElementById('app')

render(
  <SelectedEntityContextProvider>
    <EditorContentContextProvider>
      <GoogleAnalytics />
      <InertiaApp
        initialPage={JSON.parse(app.dataset.page)}
        resolveComponent={(name) =>
          import(`./Pages/${name}`).then((module) => module.default)
        }
      />
      <ToastContainer position="top-right" />
    </EditorContentContextProvider>
  </SelectedEntityContextProvider>,
  app,
)

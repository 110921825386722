import React, { useEffect, useState } from 'react'
import { Inertia } from '@inertiajs/inertia'
import { useCookies } from 'react-cookie'
import {Helmet} from 'react-helmet'

import { COOKIE_CONSENT_COOKIE_NAME } from '@/Components/CookieBanner'

const UNIVERSAL_ANALYTICS_CODE = 'UA-68043279-1'
const GOOGLE_ANALYTICS_CODE = 'G-2S741XLLNL'
// Google Tag Manager token
const GTM_TOKEN = 'GTM-TPVFXSD'

const GoogleAnalytics = () => {
  const [cookies] = useCookies([COOKIE_CONSENT_COOKIE_NAME])
  const [alreadyAccepted] = useState(
    cookies[COOKIE_CONSENT_COOKIE_NAME]?.accept,
  )
  const isGAEnabled =
      !window.location.hostname.includes('development') &&
      process.env.NODE_ENV === 'production'

  const logGAPageView = () => {
    window.gtag &&
      window.gtag('event', 'page_view', {
        page_location: window.location.href,
        page_path: window.location.pathname,
      });
  };


  // Initialize on mount, or when cookies are accepted.
  useEffect(() => {
    const cookieConsent = cookies[COOKIE_CONSENT_COOKIE_NAME]
    if (cookieConsent?.accept && isGAEnabled) {
      const eventListener = Inertia.on('navigate', () => {
        logGAPageView()
      })
      // log the page where user accepted cookies
      if (!alreadyAccepted) {
        logGAPageView()
      }
      return eventListener
    }
  }, [cookies])

  if (!cookies[COOKIE_CONSENT_COOKIE_NAME]?.accept || !isGAEnabled) {
    return null
  }

  return (
    <Helmet>
      {GOOGLE_ANALYTICS_CODE && (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_CODE}`}
        />
      )}
      {GOOGLE_ANALYTICS_CODE && (
        <script>{`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${GOOGLE_ANALYTICS_CODE}');
      `}</script>
      )}
      {
        GTM_TOKEN && (
        <script>
          {
            `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_TOKEN}');`
          }
        </script>
        )
      }
      {UNIVERSAL_ANALYTICS_CODE && (
        <script>{`
        gtag('config', '${UNIVERSAL_ANALYTICS_CODE}');
      `}</script>
      )}
    </Helmet>
  );
}

export default GoogleAnalytics

import { Transition } from '@headlessui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { InertiaLink } from '@inertiajs/inertia-react'

const LAST_POLICY_UPDATE = moment('2020-11-09', moment.HTML5_FMT.DATE) // November 9th, 2020
export const COOKIE_CONSENT_COOKIE_NAME = 'pp-cookie-consent'

const CookieBanner = () => {
  // The cookie consent cookie is either null when the user has not dismissed the banner yet,
  // or an object with a boolean property 'accept', and a 'date' property that indicates when
  // consent was given.
  const [cookies, setCookie] = useCookies([COOKIE_CONSENT_COOKIE_NAME])
  const [isOpen, setIsOpen] = useState(false)
  const params = new URLSearchParams(window.location.search)

  // If the user has neither accepted or declined cookies yet, or if the policy
  // has been updated since they did, show the banner.
  useEffect(() => {
    const cookieConsent = cookies[COOKIE_CONSENT_COOKIE_NAME]
    if (
      cookieConsent == null ||
      moment(
        cookieConsent.date,
        moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
      ).isBefore(LAST_POLICY_UPDATE)
    ) {
      setTimeout(() => setIsOpen(true), 400)
    }
  }, [cookies])

  const consentCookies = (accept) => {
    setCookie(
      COOKIE_CONSENT_COOKIE_NAME,
      {
        accept: accept,
        date: moment().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS),
      },
      {
        maxAge: 3600 * 24 * 365,
      },
    )
    setIsOpen(false)
  }

  if (params.get('nocookies') === 'true') {
    return null
  }

  return (
    <div className="absolute bottom-0 left-0 right-0 z-50 pointer-events-none">
      <Transition
        show={isOpen}
        enter="transition duration-500"
        enterFrom="opacity-0 transform -translate-y-6"
        enterTo="opacity-100 transform translate-y-0"
        leave="transition duration-500"
        leaveFrom="opacity-100 transform translate-y-0"
        leaveTo="opacity-0 transform translate-y-6"
      >
        <div className="flex justify-between items-center w-full max-w-3xl bg-white rounded-lg shadow-md mx-auto mb-12 px-6 py-4 text-gray-700 pointer-events-auto">
          <div>
            <h3 className="text-primary-dark font-semibold">
              ProGuard Playground uses cookies to enhance your user experience
            </h3>
            <p className="text-sm">
              You can view more details regarding our use of cookies in our{' '}
              <InertiaLink
                href="/cookies"
                className="text-primary-dark hover:underline"
              >
                Cookie Policy
              </InertiaLink>
              .
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <button
              className="px-3 py-2 uppercase text-xs text-white font-medium tracking-wide leading-tight rounded-lg bg-primary-light"
              onClick={() => consentCookies(true)}
            >
              Accept
            </button>
            <button
              className="px-3 py-2 uppercase text-xs font-medium tracking-wide leading-tight rounded-lg bg-secondary-lighter"
              onClick={() => consentCookies(false)}
            >
              Decline
            </button>
          </div>
        </div>
      </Transition>
    </div>
  )
}

export default CookieBanner
